import { FormFields } from '@/interfaces/Input';

const fields: FormFields = {
  name: {
    title: 'Наименование продавца',
    type: 'input',
  },
  emails: {
    title: 'Email',
    type: 'input',
  },
  phones: {
    title: 'Телефоны',
    type: 'input',
  },
  site: {
    title: 'Веб-адрес',
    type: 'input',
  },
  address: {
    title: 'Адрес',
    type: 'input',
  },
  useVat: {
    title: 'Применять НДС',
    type: 'select',
    options: [
      { title: 'Не применять', value: 0 },
      { title: 'Применять', value: 1 },
    ],
  },
  defaultVatPrecent: {
    title: 'Ставка НДС по умолчанию',
    type: 'input',
    variableType: 'number',
  },
};

export default fields;

<template>
  <div class="root">
    <ProfileForm/>
  </div>
</template>

<script>
import ProfileForm from '@/components/Profile/ProfileForm.vue';

export default {
  components: {
    ProfileForm
  }
}
</script>

<style scoped>
.root {
  display: flex;
  justify-content: center;
}
</style>
import { Entity } from './Entity';
import { IfDefineThanLength } from '@/utils/custom-validators';
import { VALID_ERRORS } from '@/constants/errors';

interface WorkspaceInterface {
  name?: string;
  emails?: string;
  phones?: string;
  site?: string;
  address?: string;
  useVat?: boolean;
  defaultVatPrecent?: number;
}

export class Workspace extends Entity<Workspace> implements WorkspaceInterface {
  @IfDefineThanLength(3, 100, { message: VALID_ERRORS.length(3, 100) })
  name?: string;
  @IfDefineThanLength(3, 128, { message: VALID_ERRORS.length(3, 128) })
  emails?: string;
  @IfDefineThanLength(3, 128, { message: VALID_ERRORS.length(3, 128) })
  phones?: string;
  @IfDefineThanLength(3, 128, { message: VALID_ERRORS.length(3, 128) })
  site?: string;
  @IfDefineThanLength(3, 256, { message: VALID_ERRORS.length(3, 256) })
  address?: string;
  useVat?: boolean;
  defaultVatPrecent?: number;

  constructor(data: WorkspaceInterface) {
    super();
    this.replaceBy(data);
  }

  replaceBy(data: WorkspaceInterface) {
    this.name = data.name || undefined;
    this.emails = data.emails || undefined;
    this.phones = data.phones || undefined;
    this.site = data.site || undefined;
    this.address = data.address || undefined;
    this.useVat = data.useVat || undefined;
    this.defaultVatPrecent = data.defaultVatPrecent || undefined;
  }
}

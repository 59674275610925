<template>
  <div class="p-formgrid p-grid p-inputtext-sm" style="fontsize: 1rem">
    <div class="p-col-12">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardFormLayout'
};
</script>

<style scoped>

</style>
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "p-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlexInput = _resolveComponent("FlexInput")
  const _component_Button = _resolveComponent("Button")
  const _component_Card = _resolveComponent("Card")

  return (_openBlock(), _createBlock(_component_Card, null, {
    content: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.layout), null, _createSlots({ _: 2 }, [
        _renderList(Object.keys(_ctx.formStructure), (slotName) => {
          return {
            name: slotName,
            fn: _withCtx(() => [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.formStructure[slotName], (key) => {
                return (_openBlock(), _createBlock("div", { key: key }, [
                  _createVNode("div", _hoisted_1, [
                    _createVNode(_component_FlexInput, {
                      modelValue: _ctx.inputVal[key],
                      "onUpdate:modelValue": ($event: any) => (_ctx.inputVal[key] = $event),
                      title: _ctx.fields[key].title,
                      id: key,
                      type: _ctx.fields[key].type,
                      variableType: _ctx.fields[key].variableType,
                      options: _ctx.fields[key].options,
                      invalid: _ctx.fields[key].invalid,
                      validationErrors: _ctx.fields[key].validationErrors,
                      onOnChange: ($event: any) => (_ctx.formHandler.checkValidation(key))
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "title", "id", "type", "variableType", "options", "invalid", "validationErrors", "onOnChange"])
                  ])
                ]))
              }), 128))
            ])
          }
        })
      ]), 1024))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Очистить",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.resetFormHandler
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        disabled: _ctx.loading,
        label: "Сохранить",
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.submitHandler
      }, null, 8, ["disabled", "onClick"])
    ]),
    _: 1
  }))
}
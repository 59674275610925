
import { ref, reactive, defineComponent, onMounted } from 'vue';

import { useToast } from 'primevue/usetoast';

import ProfileFormFields from '@/constants/FormFields/profile';
import { FormFields } from '@/interfaces/Input';
import { Workspace } from '@/classes/models/Workspace';
import { WorkspaceApi } from '@/classes/api/WorkspaceApi';
import CustomError from '@/classes/models/CustomError';

import CardForm from '@/components/UI/CardForm/CardForm.vue';

export default defineComponent({
  name: 'ProfileForm',
  components: {
    'card-form': CardForm,
  },
  setup() {
    const loading = ref(false);
    let item = reactive<Workspace>(new Workspace({}));
    const fields = reactive<FormFields>({ ...ProfileFormFields });

    const toast = useToast();
    const api = new WorkspaceApi();

    const fetch = async () => {
      loading.value = true;
      try {
        const newItem = await api.fetch();
        item = Object.assign(item, newItem);
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    onMounted(() => {
      fetch();
    });

    const submitHandler = async () => {
      loading.value = true;
      try {
        await api.update(item);
        toast.add({
          severity: 'success',
          summary: 'Успешно',
          detail: 'Успешно добавлено',
          life: 3000,
        });
      } catch (error) {
        if (error instanceof CustomError) {
          error.show('error', 5000, toast);
        } else {
          console.log(error);
        }
      }
      loading.value = false;
    };

    return {
      loading,
      item,
      fields,
      submitHandler,
    };
  },
});

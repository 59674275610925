import { Api } from './Api';
import { Workspace } from '../models/Workspace';
import CustomError from '../models/CustomError';

export class WorkspaceApi extends Api {
  async update(data: Workspace): Promise<Workspace> {
    try {
      const result = await this.updateProtected<Workspace>('workspace', data);
      return Workspace.createFromObject(Workspace, result);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }

  async fetch(): Promise<Workspace> {
    try {
      const result = await this.fetchAllProtected<Workspace[]>('workspace');
      return Workspace.createFromObject(Workspace, result[0]);
    } catch (error) {
      throw new CustomError(error.response);
    }
  }
}

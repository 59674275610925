
import { defineComponent, PropType, computed } from 'vue';

import { useToast } from 'primevue/usetoast';
import Card from 'primevue/card';
import Button from 'primevue/button';

import { RootEnitiy } from '@/interfaces/Enitiy';
import { MESSAGES } from '@/constants/errors';
import { FormFields, FormStructure } from '@/interfaces/Input';
import { FormHandler } from '@/classes/forms/FormHandler';
import DefaultLayout from './CardFormLayout.vue';

import FlexInput from '@/components/UI/Input.vue';

export default defineComponent({
  name: 'CardForm',
  emits: ['update:modelValue', 'submit', 'blank'],
  components: {
    Card,
    Button,
    FlexInput,
    'default-layout': DefaultLayout
  },
  props: {
    fields: {
      type: Object as PropType<FormFields>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<RootEnitiy>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const inputVal = computed({
      get: () => props.modelValue,
      set: val => {
        ctx.emit('update:modelValue', val);
      },
    });

    const formHandler = new FormHandler(props.fields, inputVal);
    const formStructure: FormStructure = formHandler.createFormStructure();
    const toast = useToast();

    const layout = computed(() => {
      return  'default-layout';
    });

    const submitHandler = async () => {
      const valid = await formHandler.checkValidations();
      if (valid) {
        ctx.emit('submit');
      } else {
        toast.add({
          severity: 'warn',
          summary: 'Некорректное заполнение',
          detail: MESSAGES.generalFormErrors,
          life: 3000,
        });
      }
    };

    const resetValidations = () => {
      Object.values(props.fields).forEach(field => {
        field.invalid = false;
        field.validationErrors = [];
      });
    };

    const resetFormHandler = () => {
      ctx.emit('blank');
      formHandler.setObjectToValidate(inputVal.value);
      resetValidations();
    };

    return {
      submitHandler,
      resetFormHandler,
      formHandler,
      inputVal,
      layout,
      formStructure
    };
  },
});
